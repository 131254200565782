<template>
	<article>
		<QuestionBankQuestionContent
			v-for="question in masterQuestions"
			:key="question.id"
			:question="question"
			@deleteQuestion="$emit('deleteQuestion', $event)"
			@duplicateQuestion="$emit('duplicateQuestion', $event)"
			@editQuestion="$emit('editQuestion', $event)"
		/>
	</article>
</template>

<script>
import QuestionBankQuestionContent from "./components/QuestionBankQuestionContent.vue";
export default {
	name: "QuestionBankMasterBank",
	components: { QuestionBankQuestionContent },
	props: ["masterQuestions"]
};
</script>

<style></style>
